import React, { lazy } from "react"

const Dashboard = lazy(() => import("pages/Dashboard"));
const States = lazy(() => import("pages/States"));
const Explotations = lazy(() => import("pages/Explotations"));
const Users = lazy(() => import("pages/Users"));
const Fusion = lazy(() => import("pages/Fusion"));
const Visualize = lazy(() => import("pages/Visualize"));
const Generate = lazy(() => import("pages/Generate"))
const Kmls = lazy(() => import("pages/Kmls"))
const Versiones = lazy(() => import("pages/Versiones"))

const routes = [
  {
    title: "Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
    index: true,
    text: 'Panel de control',
    subText: 'Bienvenido al Panel de control',
    groups: ['Administrador', 'Creador', 'Visualizador']
  },
  {
    title: "Generate",
    path: "/generate",
    element: <Generate />,
    index: false,
    text: 'Generador',
    subText: 'Generador de archivos de GTFS',
    groups: ['Administrador', 'Creador']
  },
  {
    title: "Visualize",
    path: "/visualize",
    element: <Visualize />,
    index: false,
    text: 'Visualizador',
    subText: 'Visualizador de archivos de GTFS',
    groups: ['Administrador', 'Creador', 'Visualizador']
  },
  {
    title: "States",
    path: "/states",
    element: <States />,
    index: false,
    text: 'Estatus',
    subText: 'Listado de estatus de generación de archivos de GTFS',
    groups: ['Administrador', 'Creador']
  },
  {
    title: "Explotations",
    path: "/explotations",
    element: <Explotations />,
    index: false,
    text: 'Explotaciones',
    subText: 'Tablas de explotaciones, contratos y líneas',
    groups: ['Administrador', 'Creador']
  },
  {
    title: "Fusion",
    path: "/fusion",
    element: <Fusion />,
    index: false,
    text: 'Fusion',
    subText: 'Fusionar archivos de GTFS',
    groups: ['Administrador', 'Creador']
  },
  {
    title: "Kmls",
    path: "/kmls",
    element: <Kmls />,
    index: false,
    text: 'KMLS',
    subText: 'Administrador de archivos KMLs',
    groups: ['Administrador', 'Creador']
  },
  {
    title: "Versiones",
    path: "/versiones",
    element: <Versiones />,
    index: false,
    text: 'Versiones',
    subText: 'Histórico de GTFS caducados',
    groups: ['Administrador', 'Creador', 'Visualizador']
  },
  {
    title: "Users",
    path: "/users",
    element: <Users />,
    index: false,
    text: 'Usuarios',
    subText: 'Administración de usuarios',
    groups: ['Administrador']
  }
]

const routesAdministrador = () => {
  return routes.map((item) => item.path)
}

const routesCreador = () => {
  return routes.filter((item) => item.groups.includes('Creador')).map((item) => item.path)
}

const routesVisualizador = () => {
  return routes.filter((item) => item.groups.includes('Visualizador')).map((item) => item.path)
}

const routesSimple = () => {
  return ['/dashboard']
}

export default routes;
export { routesAdministrador, routesCreador, routesVisualizador, routesSimple }